import { graphql } from 'gatsby'
import styled from "@emotion/styled"
import React from 'react'
import Layout from '../components/layout'
import get from 'lodash/get'
import { RichText } from 'prismic-reactjs'
import { selectHomepage, selectFields } from "../interface/api-to-component"
import { 
	PRISMIC_TYPENAME_HOMEPAGE_MONKLAND,
	PRISMIC_TYPENAME_HOMEPAGE_ATWATER
} from "../utils/prismic"
import About from "../components/about"
import CallToAction from "../components/call-to-action"
import Contact from "../components/contact"
import MenuItem from "../components/menu-item"
import SEO from "../components/seo"
import imgCheckered from "../images/checkered-14.png"
import imgFG from "../images/fg-texture.jpg"

import Image from "../components/image-logo-lucilles-marche"

import { 
  INDEX_PAGEKEYS_ABOUT,
} from "../data/assets"

import { page_links, social_links } from "../data/assets"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretRight } from "@fortawesome/free-solid-svg-icons"

export const query = graphql`
  query StringTranslations($lang: String) {
    prismic {
			allHomepage_atwaters(lang: $lang) {
				edges {
					node {
						body {
							... on PRISMIC_Homepage_atwaterBodyHero_section {
								primary {
									hero_image
									hero_headline
									hero_summary
                  hero_call_to_action_label
                  hero_call_to_action_link {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
								}
							}
						}
						summary
						title
					}
				}
			}
			allCall_to_actions(lang: $lang) {
				edges {
					node {
						_meta {
							lang
						}
						headline
						label
						link {
							... on PRISMIC__ExternalLink {
								url
							}
						}
					}
				}
			}
    }
  }
`;

const Hero = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    height: 100vh;
    opacity: 0.98;
  }
`
const HeroPanel = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  max-width: 600px;
  padding: 80px 50px;
  text-shadow: 4px 4px 4px rgba(0, 34, 96, 0.41);
  text-shadow: 4px 4px 4px #222;
  h1 {
    font-size: 34px;
    margin-bottom: 10px;
    font-family: 'Anzeigen Grotesk W01_705598';
    font-weight: normal;
    letter-spacing: 4px;
  }
  @media (min-width: 1024px) {
    top: 80px;
    padding: 120px 80px;
  }
  @media (min-width: 1600px) {
    bottom: 80px;
    right: 80px;
  }
`
const HeroMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${imgCheckered});
`
const BookingBar = styled.div`
  position: absolute;
  top: 120px;
  right: 0;
  z-index: 999;
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, .2);
  background-image: url(${imgFG});
  @media (min-width: 480px) {
    position: absolute;
    top: 180px;
  }
`
const Outline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 4px 0 4px 4px;
`
const Inline = styled.div`
  border-top: 1px solid #00aad4;
  border-left: 1px solid #00aad4;
  border-bottom: 1px solid #00aad4;
  margin: 1px 0 1px 1px;
`
const Anchor = styled.a`
  font-size: 12px;
  padding: 10px 30px;
  text-decoration: none;
`
const AddressBar = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin-bottom: 20px;
  padding: 4px 0;
  font-size: 12px;
  color: #fff;
  background-image: url(${imgFG});
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
`
const Left= styled.div`
  width: 30%;
  @media (min-width: 380px) {
    width: 45%;
  }
  @media (min-width: 480px) {
    width: 20%;
  }
`
const Right = styled.div`
  text-align: right;
  width: 70%;
  @media (min-width: 380px) {
    width: 55%;
  }
  @media (min-width: 480px) {
    width: 80%;
  }
`
const BG = styled.div`
  height: 100vh;
  opacity: 0.98;
  position: relative;
  overflow: hidden;
`
const IMG = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition-delay: 500ms;
`
const BlueBar = styled.div`
	background-color: #00ABD4;
	height: 4px;
	width:100%;
	max-width: 1000px;
	margin: 10px auto;
`
const BlueBarThin = styled.div`
	background-color: #00ABD4;
	height: 1px;
	width:100%;
	max-width: 1000px;
	margin: 4px auto;
`
const GreyBar = styled.div`
	background-color: #414141;
	height: 4px;
	width:100%;
	max-width: 1000px;
	margin: 10px auto;
`
const BlackBar = styled.div`
	background-color: #1C1A1A;
	height: 4px;
	width:100%;
	max-width: 1000px;
	margin: 10px auto;
`

const Page = (props) => {

	const allHomepage = props.data.prismic.allHomepage_atwaters
	const allCall = props.data.prismic.allCall_to_actions

  if(!allHomepage.edges[0])
    return null
  if(!allCall.edges[0])
    return null

  const title = get(allHomepage, 'edges.0.node.title', [])
  const summary = get(allHomepage, 'edges.0.node.summary', [])
  const contact_section = get(allHomepage, 'edges.0.node.contact_section', [])
  const description = get(allHomepage, 'edges.0.node.description', '')
  const lang = get(props.pageContext, 'lang', '')

  const homepage =  selectHomepage(
    props.data, PRISMIC_TYPENAME_HOMEPAGE_ATWATER)

  const headline = get(allCall, 'edges.0.node.headline', [])
  const label = get(allCall, 'edges.0.node.label', '')
  const link = get(allCall, 'edges.0.node.link.url', '')

  const locale = lang === 'fr-ca' ? 'fr' : 'en'

  return (
    <Layout 
      lang={lang} 
      call_to_action_label={homepage.hero_call_to_action_label}
      call_to_action_link={homepage.hero_call_to_action_link.url}
      page_links={page_links}
      social_links={social_links}
    >
      <SEO 
				lang={lang}
        title={RichText.asText(title)} 
        description={description}
      />
			<BlueBar />
			<GreyBar />
      <div ref={page_links[locale][INDEX_PAGEKEYS_ABOUT].ref}>
      </div>
      <Hero>
        <BG><IMG src={homepage.hero_image.url} alt={homepage.hero_image.alt} /></BG>
        <HeroMask />
        <HeroPanel>
          
          { RichText.render(homepage.hero_headline) }
			<BlueBarThin />
			<BlueBarThin />
          { RichText.render(homepage.hero_summary) }
        </HeroPanel>
      </Hero>

			<BlueBar />

    { /*
      <CallToAction 
        headline={headline}
        label={label}
        link={link}
      />
    */ }

    </Layout>
  );
}

export default Page
