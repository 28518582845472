import React from "react"
import styled from "@emotion/styled"
import { RichText } from 'prismic-reactjs'

import imgBG from "../images/bg-texture.jpg"

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-image: url(${imgBG});
  @media (min-width: 768px) {
    padding: 80px 40px;
  }
  @media (min-width: 1024px) {
  }
`
const Footline = styled.div`
  max-width: 600px;
  padding-right: 50px;
  p {
    margin-bottom: 0;
  }
  @media (min-width: 1024px) {
  }
`
const ButtonLink = styled.a`
  text-align: center;
  min-width: 110px;
  display: inline-block;
  background-color: #fff;
  padding: 8px 12px;
  color: #060809;
  font-family: 'Oswald';
  font-size: 16px;
  border-bottom:2px solid #333;
  border-right:2px solid #333;
  &:visited {
    color: #060809;
  }
  &:hover {
    color: #00ABD4;
    border-bottom:2px solid #00ABD4;
    border-right:2px solid #00ABD4;
  }
  @media (min-width: 1024px) {
    padding: 8px 24px;
    bottom: 20px;
    right: 10px;
  }
`

const CallToAction = ({ headline, label, link }) => {
  return (
    <Footer>
      <Footline>
        { RichText.render(headline) }
      </Footline>
      <ButtonLink href={link}>{ label }</ButtonLink>
    </Footer>
  )
}

export default CallToAction
